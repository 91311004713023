

// $border-radius:   0;
$font-size-base:  .85rem;
$line-height-base:  1.4;

$body-bg:       #f7f9fc;
$body-color:    #333;
// $primary:       #13253d;
$primary:   #345dfa;
$success:  #198754;
$warning:   #f90;
$secondary: #13253d;
$info:     #4a9df6;

$headings-color: #333;

$gray: #666;

// tables
$table-bg-scale: -60%;

// calendar
$month-color: #999;
$week-color: #b6c5d2;

$min-contrast-ratio:   2.1 !default;


$table-bg: #fff;
$card-bg: #fff;
$dropdown-bg: #fff;