

// bootstrap
@import './variables.scss';

@import '~bootstrap/scss/bootstrap.scss';


// plugins

// select2
@import '~select2';
// datetimepicker
@import "jquery-datetimepicker/jquery.datetimepicker.css";
// dataTables
@import "~datatables.net-bs5/css/dataTables.bootstrap5.css";
// daterangepicker
@import "~daterangepicker/daterangepicker.css";